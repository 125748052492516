@import 'styles/theme';

.SliderControls {
  display: flex;
  height: 8px;

  .button {
    position: relative;
    flex-shrink: 0;
    box-sizing: border-box;
    margin-right: 10px;
    cursor: pointer;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: transparent;
    box-sizing: border-box;

    &:last-child {
      margin-right: 0;
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      border-radius: 50%;
      box-sizing: border-box;
    }

    &:before {
      transform: translate(-50%, -50%);
      background-color: currentColor;
      width: 100%;
      height: 100%;
      opacity: 0.3;
    }

    &:after {
      display: none;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      background-color: currentColor;
    }

    &.active {
      &:before {
        display: none;
      }

      &:after {
        display: block;
      }
    }
  }
}
