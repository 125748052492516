@import 'styles/theme';

.cta {
  margin-left: auto;

  @include from('md') {
    margin-left: 20px;
  }
  &.isLoading {
    pointer-events: none;
  }
}
