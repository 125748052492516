@import 'styles/theme';

.CtaWithPrice {
  width: 100%;

  color: theme($colors, 'white');
  padding: 0px theme($spacings, 22);
  position: relative;
  height: 56px;
  min-height: 56px;
  border-radius: theme($borderRadius, 'default');
  padding-bottom: 2px;
  background-color: theme($colors, 'blue');

  @include from('md') {
    &:hover {
      background-color: lighten(theme($colors, 'blue'), 20%);
    }
  }

  &.isLoading {
    cursor: not-allowed;
    pointer-events: none;
    @include from('md') {
      &:hover {
        background-color: theme($colors, 'blue');
      }
    }
  }

  &.snow-drift {
    background-color: theme($colors, 'shade-3');
    color: theme($colors, 'black');

    @include from('md') {
      &:hover {
        background-color: theme($colors, 'shade-4');
      }
    }
  }

  @include set-transition(
    (
      'border-radius': 'default',
      'width': 'default',
      background-color: 'default',
    )
  );

  &.disabled {
    cursor: not-allowed;
    color: theme($colors, 'shade-6');
    background-color: theme($colors, 'shade-5');
  }

  .spinnerContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .spinner {
      width: 22px;
    }
  }

  .buttonLabel {
    display: inline-flex;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    align-items: center;
  }

  .price {
    @include background-opacity(theme($colors, 'white'), 0.15);
    margin-left: theme($spacings, 8);
    line-height: 0.5;
    letter-spacing: 0.05em;
    padding: theme($spacings, 5) theme($spacings, 3) theme($spacings, 5)
      theme($spacings, 3);
    border-radius: theme($borderRadius, 'default');
  }

  .content {
    position: relative;
  }

  &.isFullscreen {
    &:first-child,
    &:last-child {
      border-radius: 0;
    }
  }
}
