.styles_Accordion__1X3sC{width:100%;overflow:hidden}.styles_titleWrapper__dMYVk{width:100%;display:flex;justify-content:space-between}.styles_link__lRiQy{text-transform:uppercase;text-align:left}.styles_subPane__CUTth{height:0}.styles_arrow__5i8P3{transform-origin:45% 45%;transition:transform 0.2s ease-in-out}.styles_arrow__5i8P3.styles_isOpen__4ta2Q{transform:rotate(90deg)}.styles_dark__jybgU{background-color:red}.styles_dark__jybgU .styles_arrow__5i8P3{background-color:blue}.styles_dark__jybgU .styles_arrow__5i8P3 path{stroke:#000}
.styles_AccordionPanel__JVjyw{overflow:hidden}.styles_head__1JBPs{width:100%;display:flex;justify-content:space-between;cursor:pointer;-webkit-tap-highlight-color:rgba(0,0,0,0)}.styles_arrowWrapper__AgU2R{flex:1 1;text-align:right}.styles_arrow__5XeiL{transform-origin:45% 45%;transition:transform 0.2s ease-in-out}.styles_arrow__5XeiL.styles_isOpen__9Lbxr{transform:rotate(90deg)}.styles_arrow__5XeiL.styles_dark__owZLL{transform:rotate(-180deg)}.styles_arrow__5XeiL.styles_dark__owZLL.styles_isOpen__9Lbxr{transform:rotate(0)}.styles_body__qcLYJ{height:0;overflow:hidden}
.styles_CtaWithPrice__yMf0N{width:100%;color:#fff;padding:0px;position:relative;height:56px;min-height:56px;border-radius:2px;padding-bottom:2px;background-color:#191b4a;transition:border-radius 0.2s ease-in-out, width 0.2s ease-in-out, background-color 0.2s ease-in-out}@media screen and (min-width: 768px){.styles_CtaWithPrice__yMf0N:hover{background-color:#333796}}.styles_CtaWithPrice__yMf0N.styles_isLoading__4YRlK{cursor:not-allowed;pointer-events:none}@media screen and (min-width: 768px){.styles_CtaWithPrice__yMf0N.styles_isLoading__4YRlK:hover{background-color:#191b4a}}.styles_CtaWithPrice__yMf0N.styles_snow-drift__9L3h_{background-color:#f5f5f5;color:#000}@media screen and (min-width: 768px){.styles_CtaWithPrice__yMf0N.styles_snow-drift__9L3h_:hover{background-color:#f2f0ea}}.styles_CtaWithPrice__yMf0N.styles_disabled__eqvzz{cursor:not-allowed;color:gray;background-color:#f2f2f2}.styles_CtaWithPrice__yMf0N .styles_spinnerContainer__PT1R8{position:absolute;top:50%;left:50%;transform:translate(-50%, -50%)}.styles_CtaWithPrice__yMf0N .styles_spinnerContainer__PT1R8 .styles_spinner__3rSjl{width:22px}.styles_CtaWithPrice__yMf0N .styles_buttonLabel__z9LJk{display:inline-flex;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;align-items:center}.styles_CtaWithPrice__yMf0N .styles_price__GHakh{background:#fff;background:rgba(255,255,255,.15);margin-left:8px;line-height:.5;letter-spacing:.05em;padding:5px 3px 5px 3px;border-radius:2px}.styles_CtaWithPrice__yMf0N .styles_content__S1hbb{position:relative}.styles_CtaWithPrice__yMf0N.styles_isFullscreen__jD2KU:first-child,.styles_CtaWithPrice__yMf0N.styles_isFullscreen__jD2KU:last-child{border-radius:0}
.styles_SliderControls__vfH7Y{display:flex;height:8px}.styles_SliderControls__vfH7Y .styles_button__VTBlF{position:relative;flex-shrink:0;box-sizing:border-box;margin-right:10px;cursor:pointer;width:8px;height:8px;border-radius:50%;background-color:rgba(0,0,0,0);box-sizing:border-box}.styles_SliderControls__vfH7Y .styles_button__VTBlF:last-child{margin-right:0}.styles_SliderControls__vfH7Y .styles_button__VTBlF:before,.styles_SliderControls__vfH7Y .styles_button__VTBlF:after{content:"";position:absolute;top:50%;left:50%;border-radius:50%;box-sizing:border-box}.styles_SliderControls__vfH7Y .styles_button__VTBlF:before{transform:translate(-50%, -50%);background-color:currentColor;width:100%;height:100%;opacity:.3}.styles_SliderControls__vfH7Y .styles_button__VTBlF:after{display:none;transform:translate(-50%, -50%);width:100%;height:100%;background-color:currentColor}.styles_SliderControls__vfH7Y .styles_button__VTBlF.styles_active__rvrpy:before{display:none}.styles_SliderControls__vfH7Y .styles_button__VTBlF.styles_active__rvrpy:after{display:block}
.styles_cta__LX_Pm{margin-left:auto}@media screen and (min-width: 768px){.styles_cta__LX_Pm{margin-left:20px}}.styles_cta__LX_Pm.styles_isLoading__SQ3Kc{pointer-events:none}
.styles_SliderArrow__S3BNd{width:50px;height:50px;padding:0;cursor:pointer}.styles_SliderArrow__S3BNd .styles_arrowIcon__UUzyL{display:block}.styles_SliderArrow__S3BNd .styles_arrowIcon__UUzyL.styles_black__uka2_ path{stroke:#fff}.styles_SliderArrow__S3BNd .styles_arrowIcon__UUzyL path{stroke:#000}.styles_SliderArrow__S3BNd.styles_black__uka2_ .styles_arrowIcon__UUzyL path{stroke:#fff}
.styles_TagPreOrder__dd_Tw{display:inline-flex;align-items:center}.styles_TagPreOrder__dd_Tw .styles_label__mjLtQ{display:flex;align-items:center}
button.styles_sliderArrow__6mTYy{position:absolute;top:50%}button.styles_sliderArrow__6mTYy.styles_hide__dmqXD{display:none}button.styles_sliderArrow__6mTYy.styles_layout-s__1i0vn{width:30px;height:30px}button.styles_sliderArrow__6mTYy.styles_layout-s__1i0vn svg{width:30px}button.styles_sliderArrow__6mTYy.styles_layout-xs__JvetS{width:30px;height:30px}button.styles_sliderArrow__6mTYy.styles_layout-xs__JvetS svg{width:24px}@media screen and (min-width: 768px){button.styles_sliderArrow__6mTYy{display:flex}}.styles_leftArrow__fpLrk{left:0;transform:translate(-20%, -50%) rotate(180deg)}.styles_leftArrow__fpLrk.styles_inside__o4Har{transform:translate(20%, -50%) rotate(180deg)}.styles_rightArrow__Zh6U8{right:0;transform:translate(20%, -50%) rotate(0deg)}.styles_rightArrow__Zh6U8.styles_inside__o4Har{transform:translate(-20%, -50%) rotate(0deg)}
.styles_CardProduct__ywNfc{position:relative;border-radius:2px;overflow:hidden;display:flex;flex-direction:column;border-radius:0}.styles_CardProduct__ywNfc .styles_cardContainer__bu_KW{position:relative;display:flex;flex-direction:column;height:100%}.styles_CardProduct__ywNfc .styles_cardMediaWrapper__Jpi5B{cursor:pointer}.styles_CardProduct__ywNfc .styles_cardLink__V9FpN{position:relative;display:flex;flex-direction:column;justify-content:center;flex:1 1}.styles_CardProduct__ywNfc .styles_compareAtPrice__Ptve1{display:inline-block;vertical-align:middle;margin-right:10px;color:#bc2f26}.styles_CardProduct__ywNfc .styles_cardTop__iZDw9{display:flex;align-items:center;position:absolute;left:0;right:0;z-index:2;flex-direction:row;top:0;padding:10px;padding:calc(
    calc((
        calc(
    calc(100vw - var(--scroll-bar)) - (clamp(16px, 4vw, 32px) * 2) - ((
            6 - 1
          ) * clamp(16px, 4vw, 32px))
  ) / 6 *
          0
      ) + (clamp(16px, 4vw, 32px) * max(0, (-1)))) + (
        clamp(16px, 4vw, 32px) * 0
      ) + (clamp(16px, 4vw, 32px) * 1)
  )}@media screen and (min-width: 1940px){.styles_CardProduct__ywNfc .styles_cardTop__iZDw9{padding:calc(
      calc((
        calc((1940px - var(--scroll-bar-grid)) - (clamp(16px, 4vw, 32px) * 2) - ((6 - 1) * clamp(16px, 4vw, 32px))) / 6 * 0
      ) + (clamp(16px, 4vw, 32px) * max(0, (0 - 1)))) + (
          clamp(16px, 4vw, 32px) * 0
        ) + (clamp(16px, 4vw, 32px) * 1)
    )}}.styles_CardProduct__ywNfc .styles_cardTop__iZDw9 .styles_cardRight__Aw7IT{display:flex;flex-direction:column;align-items:center;padding-left:0;flex:1 1;justify-content:flex-end}@media screen and (min-width: 768px){.styles_CardProduct__ywNfc .styles_cardTop__iZDw9 .styles_cardRight__Aw7IT{flex-flow:row wrap;justify-content:flex-start}}.styles_CardProduct__ywNfc .styles_label__ES3yL{margin-bottom:4px;margin-right:4px}.styles_CardProduct__ywNfc .styles_cardImages__slLEY{box-sizing:border-box;position:relative;overflow:hidden;border-radius:2px}.styles_CardProduct__ywNfc .styles_addToCart__SAQkX{padding:10px;border-top-left-radius:0;border-top-right-radius:0;box-sizing:border-box;top:0px}.styles_CardProduct__ywNfc .styles_hoverImage__VlEra{position:absolute;z-index:1;top:0;left:0;width:100%;height:100%}.styles_CardProduct__ywNfc .styles_cardBottom__9r_c7{position:relative;display:flex;align-items:center;flex-direction:column;text-align:center;height:100%;bottom:0;left:0;right:0;border-radius:0;padding:0 8px;margin-top:12px}@media screen and (min-width: 768px){.styles_CardProduct__ywNfc .styles_cardBottom__9r_c7{margin-top:16px}}.styles_CardProduct__ywNfc .styles_cardBottom__9r_c7 .styles_cardRight__Aw7IT{padding-top:4px;display:flex;flex-direction:column;align-items:center;padding-left:0;flex-flow:row wrap}.styles_CardProduct__ywNfc .styles_cardBottom__9r_c7 .styles_cardLeft__wOdHD{text-align:center}.styles_CardProduct__ywNfc .styles_cardBottom__9r_c7{margin-top:20px}@media screen and (min-width: 768px){.styles_CardProduct__ywNfc .styles_cardBottom__9r_c7{margin-top:20px;border-radius:2px;transition:background 0.2s ease-in-out}}.styles_CardProduct__ywNfc .styles_cta__hanB_{margin-top:8px}.styles_CardProduct__ywNfc .styles_cta__hanB_+.styles_cardBottom__9r_c7{margin-top:6px}.styles_CardProduct__ywNfc.styles_isFullWidth__vbBou{border-radius:0}
.styles_SliderProducts__DsTeM{max-width:100%;overflow:hidden}@media screen and (min-width: 768px){.styles_SliderProducts__DsTeM{overflow:visible}}.styles_SliderProducts__DsTeM .styles_controls__mLK3m{display:flex;justify-content:center}.styles_SliderProducts__DsTeM .styles_controls__mLK3m .styles_sliderControls__mZWHC{margin-top:28px}.styles_SliderProducts__DsTeM .styles_sliderContainer__zTOyU{position:relative;width:100%}@media screen and (min-width: 768px){.styles_SliderProducts__DsTeM .styles_sliderContainer__zTOyU{max-width:1940px;margin-left:auto;margin-right:auto}}.styles_SliderProducts__DsTeM .styles_slider__Nd96w{position:relative;width:100%;box-sizing:border-box}.styles_SliderProducts__DsTeM .styles_sliderItem__ewN6s{position:relative}.styles_SliderProducts__DsTeM.styles_s__EtIJr .styles_slider__Nd96w{margin-left:calc(
    calc((
        calc(
    calc(100vw - var(--scroll-bar)) - (clamp(16px, 4vw, 32px) * 2) - ((
            6 - 1
          ) * clamp(16px, 4vw, 32px))
  ) / 6 *
          0
      ) + (clamp(16px, 4vw, 32px) * max(0, (-1)))) + (
        clamp(16px, 4vw, 32px) * 0
      ) + (clamp(16px, 4vw, 32px) * 1)
  )}@media screen and (min-width: 1940px){.styles_SliderProducts__DsTeM.styles_s__EtIJr .styles_slider__Nd96w{margin-left:calc(
      calc((
        calc((1940px - var(--scroll-bar-grid)) - (clamp(16px, 4vw, 32px) * 2) - ((6 - 1) * clamp(16px, 4vw, 32px))) / 6 * 0
      ) + (clamp(16px, 4vw, 32px) * max(0, (0 - 1)))) + (
          clamp(16px, 4vw, 32px) * 0
        ) + (clamp(16px, 4vw, 32px) * 1)
    )}}@media screen and (min-width: 768px){.styles_SliderProducts__DsTeM.styles_s__EtIJr .styles_slider__Nd96w{margin-left:0}}.styles_SliderProducts__DsTeM.styles_s__EtIJr .styles_sliderItem__ewN6s{width:calc(
    calc((
        calc(
    calc(100vw - var(--scroll-bar)) - (clamp(16px, 4vw, 32px) * 2) - ((
            6 - 1
          ) * clamp(16px, 4vw, 32px))
  ) / 6 *
          3
      ) + (clamp(16px, 4vw, 32px) * max(0, (2)))) + (
        clamp(16px, 4vw, 32px) * 0
      ) + (clamp(16px, 4vw, 32px) * 0)
  );margin-left:calc(
    calc((
        calc(
    calc(100vw - var(--scroll-bar)) - (clamp(16px, 4vw, 32px) * 2) - ((
            6 - 1
          ) * clamp(16px, 4vw, 32px))
  ) / 6 *
          0
      ) + (clamp(16px, 4vw, 32px) * max(0, (-1)))) + (
        clamp(16px, 4vw, 32px) * 0
      ) + (clamp(16px, 4vw, 32px) * 1)
  )}@media screen and (min-width: 1940px){.styles_SliderProducts__DsTeM.styles_s__EtIJr .styles_sliderItem__ewN6s{width:calc(
      calc((
        calc((1940px - var(--scroll-bar-grid)) - (clamp(16px, 4vw, 32px) * 2) - ((6 - 1) * clamp(16px, 4vw, 32px))) / 6 * 3
      ) + (clamp(16px, 4vw, 32px) * max(0, (3 - 1)))) + (
          clamp(16px, 4vw, 32px) * 0
        ) + (clamp(16px, 4vw, 32px) * 0)
    )}}@media screen and (min-width: 1940px){.styles_SliderProducts__DsTeM.styles_s__EtIJr .styles_sliderItem__ewN6s{margin-left:calc(
      calc((
        calc((1940px - var(--scroll-bar-grid)) - (clamp(16px, 4vw, 32px) * 2) - ((6 - 1) * clamp(16px, 4vw, 32px))) / 6 * 0
      ) + (clamp(16px, 4vw, 32px) * max(0, (0 - 1)))) + (
          clamp(16px, 4vw, 32px) * 0
        ) + (clamp(16px, 4vw, 32px) * 1)
    )}}@media screen and (min-width: 930px){.styles_SliderProducts__DsTeM.styles_s__EtIJr .styles_sliderItem__ewN6s{width:calc(
    calc((
        calc(
    calc(100vw - var(--scroll-bar)) - (24px * 2) - ((
            12 - 1
          ) * 24px)
  ) / 12 *
          2
      ) + (24px * max(0, (1)))) + (
        24px * 0
      ) + (24px * 0)
  );margin-left:calc(
    calc((
        calc(
    calc(100vw - var(--scroll-bar)) - (24px * 2) - ((
            12 - 1
          ) * 24px)
  ) / 12 *
          0
      ) + (24px * max(0, (-1)))) + (
        24px * 0
      ) + (24px * 1)
  )}}@media screen and (min-width: 930px)and (min-width: 1940px){.styles_SliderProducts__DsTeM.styles_s__EtIJr .styles_sliderItem__ewN6s{width:calc(
      calc((
        calc((1940px - var(--scroll-bar-grid)) - (24px * 2) - ((12 - 1) * 24px)) / 12 * 2
      ) + (24px * max(0, (2 - 1)))) + (
          24px * 0
        ) + (24px * 0)
    )}}@media screen and (min-width: 930px)and (min-width: 1940px){.styles_SliderProducts__DsTeM.styles_s__EtIJr .styles_sliderItem__ewN6s{margin-left:calc(
      calc((
        calc((1940px - var(--scroll-bar-grid)) - (24px * 2) - ((12 - 1) * 24px)) / 12 * 0
      ) + (24px * max(0, (0 - 1)))) + (
          24px * 0
        ) + (24px * 1)
    )}}@media screen and (min-width: 768px){.styles_SliderProducts__DsTeM.styles_xs__I1XWA .styles_slider__Nd96w{margin-left:0}}.styles_SliderProducts__DsTeM.styles_xs__I1XWA .styles_sliderItem__ewN6s{width:165px;margin-left:16px}@media screen and (min-width: 1024px){.styles_SliderProducts__DsTeM.styles_xs__I1XWA .styles_sliderItem__ewN6s{width:180px}}.styles_SliderProducts__DsTeM.styles_m__vS2Ly .styles_slider__Nd96w{margin-left:calc(
    calc((
        calc(
    calc(100vw - var(--scroll-bar)) - (clamp(16px, 4vw, 32px) * 2) - ((
            6 - 1
          ) * clamp(16px, 4vw, 32px))
  ) / 6 *
          0
      ) + (clamp(16px, 4vw, 32px) * max(0, (-1)))) + (
        clamp(16px, 4vw, 32px) * 0
      ) + (clamp(16px, 4vw, 32px) * 1)
  )}@media screen and (min-width: 1940px){.styles_SliderProducts__DsTeM.styles_m__vS2Ly .styles_slider__Nd96w{margin-left:calc(
      calc((
        calc((1940px - var(--scroll-bar-grid)) - (clamp(16px, 4vw, 32px) * 2) - ((6 - 1) * clamp(16px, 4vw, 32px))) / 6 * 0
      ) + (clamp(16px, 4vw, 32px) * max(0, (0 - 1)))) + (
          clamp(16px, 4vw, 32px) * 0
        ) + (clamp(16px, 4vw, 32px) * 1)
    )}}@media screen and (min-width: 768px){.styles_SliderProducts__DsTeM.styles_m__vS2Ly .styles_slider__Nd96w{margin-left:0}}.styles_SliderProducts__DsTeM.styles_m__vS2Ly .styles_sliderItem__ewN6s{width:calc(
    calc((
        calc(
    calc(100vw - var(--scroll-bar)) - (clamp(16px, 4vw, 32px) * 2) - ((
            6 - 1
          ) * clamp(16px, 4vw, 32px))
  ) / 6 *
          5.5
      ) + (clamp(16px, 4vw, 32px) * max(0, (4.5)))) + (
        clamp(16px, 4vw, 32px) * 0
      ) + (clamp(16px, 4vw, 32px) * 0)
  );margin-left:calc(
    calc((
        calc(
    calc(100vw - var(--scroll-bar)) - (clamp(16px, 4vw, 32px) * 2) - ((
            6 - 1
          ) * clamp(16px, 4vw, 32px))
  ) / 6 *
          0
      ) + (clamp(16px, 4vw, 32px) * max(0, (-1)))) + (
        clamp(16px, 4vw, 32px) * 0
      ) + (clamp(16px, 4vw, 32px) * 1)
  )}@media screen and (min-width: 1940px){.styles_SliderProducts__DsTeM.styles_m__vS2Ly .styles_sliderItem__ewN6s{width:calc(
      calc((
        calc((1940px - var(--scroll-bar-grid)) - (clamp(16px, 4vw, 32px) * 2) - ((6 - 1) * clamp(16px, 4vw, 32px))) / 6 * 5.5
      ) + (clamp(16px, 4vw, 32px) * max(0, (5.5 - 1)))) + (
          clamp(16px, 4vw, 32px) * 0
        ) + (clamp(16px, 4vw, 32px) * 0)
    )}}@media screen and (min-width: 1940px){.styles_SliderProducts__DsTeM.styles_m__vS2Ly .styles_sliderItem__ewN6s{margin-left:calc(
      calc((
        calc((1940px - var(--scroll-bar-grid)) - (clamp(16px, 4vw, 32px) * 2) - ((6 - 1) * clamp(16px, 4vw, 32px))) / 6 * 0
      ) + (clamp(16px, 4vw, 32px) * max(0, (0 - 1)))) + (
          clamp(16px, 4vw, 32px) * 0
        ) + (clamp(16px, 4vw, 32px) * 1)
    )}}@media screen and (min-width: 768px){.styles_SliderProducts__DsTeM.styles_m__vS2Ly .styles_sliderItem__ewN6s{width:calc(
    calc((
        calc(
    calc(100vw - var(--scroll-bar)) - (24px * 2) - ((
            12 - 1
          ) * 24px)
  ) / 12 *
          3
      ) + (24px * max(0, (2)))) + (
        24px * 0
      ) + (24px * 0)
  );margin-left:calc(
    calc((
        calc(
    calc(100vw - var(--scroll-bar)) - (24px * 2) - ((
            12 - 1
          ) * 24px)
  ) / 12 *
          0
      ) + (24px * max(0, (-1)))) + (
        24px * 0
      ) + (24px * 1)
  )}}@media screen and (min-width: 768px)and (min-width: 1940px){.styles_SliderProducts__DsTeM.styles_m__vS2Ly .styles_sliderItem__ewN6s{width:calc(
      calc((
        calc((1940px - var(--scroll-bar-grid)) - (24px * 2) - ((12 - 1) * 24px)) / 12 * 3
      ) + (24px * max(0, (3 - 1)))) + (
          24px * 0
        ) + (24px * 0)
    )}}@media screen and (min-width: 768px)and (min-width: 1940px){.styles_SliderProducts__DsTeM.styles_m__vS2Ly .styles_sliderItem__ewN6s{margin-left:calc(
      calc((
        calc((1940px - var(--scroll-bar-grid)) - (24px * 2) - ((12 - 1) * 24px)) / 12 * 0
      ) + (24px * max(0, (0 - 1)))) + (
          24px * 0
        ) + (24px * 1)
    )}}.styles_SliderProducts__DsTeM.styles_xxs__VVtAE .styles_slider__Nd96w{margin-left:calc(
    calc((
        calc(
    calc(100vw - var(--scroll-bar)) - (clamp(16px, 4vw, 32px) * 2) - ((
            6 - 1
          ) * clamp(16px, 4vw, 32px))
  ) / 6 *
          0
      ) + (clamp(16px, 4vw, 32px) * max(0, (-1)))) + (
        clamp(16px, 4vw, 32px) * 0
      ) + (clamp(16px, 4vw, 32px) * 0)
  )}@media screen and (min-width: 1940px){.styles_SliderProducts__DsTeM.styles_xxs__VVtAE .styles_slider__Nd96w{margin-left:calc(
      calc((
        calc((1940px - var(--scroll-bar-grid)) - (clamp(16px, 4vw, 32px) * 2) - ((6 - 1) * clamp(16px, 4vw, 32px))) / 6 * 0
      ) + (clamp(16px, 4vw, 32px) * max(0, (0 - 1)))) + (
          clamp(16px, 4vw, 32px) * 0
        ) + (clamp(16px, 4vw, 32px) * 0)
    )}}@media screen and (min-width: 768px){.styles_SliderProducts__DsTeM.styles_xxs__VVtAE .styles_slider__Nd96w{margin-left:0}}.styles_SliderProducts__DsTeM.styles_xxs__VVtAE .styles_sliderItem__ewN6s{width:calc(
    calc((
        calc(
    calc(100vw - var(--scroll-bar)) - (clamp(16px, 4vw, 32px) * 2) - ((
            6 - 1
          ) * clamp(16px, 4vw, 32px))
  ) / 6 *
          3
      ) + (clamp(16px, 4vw, 32px) * max(0, (2)))) + (
        clamp(16px, 4vw, 32px) * 0
      ) + (clamp(16px, 4vw, 32px) * 0)
  );margin-left:calc(
    calc((
        calc(
    calc(100vw - var(--scroll-bar)) - (clamp(16px, 4vw, 32px) * 2) - ((
            6 - 1
          ) * clamp(16px, 4vw, 32px))
  ) / 6 *
          0
      ) + (clamp(16px, 4vw, 32px) * max(0, (-1)))) + (
        clamp(16px, 4vw, 32px) * 0
      ) + (clamp(16px, 4vw, 32px) * 1)
  )}@media screen and (min-width: 1940px){.styles_SliderProducts__DsTeM.styles_xxs__VVtAE .styles_sliderItem__ewN6s{width:calc(
      calc((
        calc((1940px - var(--scroll-bar-grid)) - (clamp(16px, 4vw, 32px) * 2) - ((6 - 1) * clamp(16px, 4vw, 32px))) / 6 * 3
      ) + (clamp(16px, 4vw, 32px) * max(0, (3 - 1)))) + (
          clamp(16px, 4vw, 32px) * 0
        ) + (clamp(16px, 4vw, 32px) * 0)
    )}}@media screen and (min-width: 1940px){.styles_SliderProducts__DsTeM.styles_xxs__VVtAE .styles_sliderItem__ewN6s{margin-left:calc(
      calc((
        calc((1940px - var(--scroll-bar-grid)) - (clamp(16px, 4vw, 32px) * 2) - ((6 - 1) * clamp(16px, 4vw, 32px))) / 6 * 0
      ) + (clamp(16px, 4vw, 32px) * max(0, (0 - 1)))) + (
          clamp(16px, 4vw, 32px) * 0
        ) + (clamp(16px, 4vw, 32px) * 1)
    )}}@media screen and (min-width: 768px){.styles_SliderProducts__DsTeM.styles_xxs__VVtAE .styles_sliderItem__ewN6s{width:calc(
    calc((
        calc(
    calc(100vw - var(--scroll-bar)) - (24px * 2) - ((
            12 - 1
          ) * 24px)
  ) / 12 *
          2
      ) + (24px * max(0, (1)))) + (
        24px * 0
      ) + (24px * 0)
  );margin-left:calc(
    calc((
        calc(
    calc(100vw - var(--scroll-bar)) - (24px * 2) - ((
            12 - 1
          ) * 24px)
  ) / 12 *
          0
      ) + (24px * max(0, (-1)))) + (
        24px * 0
      ) + (24px * 1)
  )}}@media screen and (min-width: 768px)and (min-width: 1940px){.styles_SliderProducts__DsTeM.styles_xxs__VVtAE .styles_sliderItem__ewN6s{width:calc(
      calc((
        calc((1940px - var(--scroll-bar-grid)) - (24px * 2) - ((12 - 1) * 24px)) / 12 * 2
      ) + (24px * max(0, (2 - 1)))) + (
          24px * 0
        ) + (24px * 0)
    )}}@media screen and (min-width: 768px)and (min-width: 1940px){.styles_SliderProducts__DsTeM.styles_xxs__VVtAE .styles_sliderItem__ewN6s{margin-left:calc(
      calc((
        calc((1940px - var(--scroll-bar-grid)) - (24px * 2) - ((12 - 1) * 24px)) / 12 * 0
      ) + (24px * max(0, (0 - 1)))) + (
          24px * 0
        ) + (24px * 1)
    )}}
