@import 'styles/theme';

.SliderProducts {
  max-width: 100%;
  overflow: hidden;

  @include from('md') {
    overflow: visible;
  }

  .controls {
    display: flex;
    justify-content: center;
    .sliderControls {
      margin-top: 28px;
    }
  }

  .sliderContainer {
    position: relative;
    width: 100%;

    @include from('md') {
      max-width: theme($screens, 'max-grid-width');
      margin-left: auto;
      margin-right: auto;
    }
  }

  .slider {
    position: relative;
    width: 100%;
    box-sizing: border-box;
  }

  .sliderItem {
    position: relative;
  }

  &.s {
    .slider {
      @include setPropertyValue(
        (
          'values': $mobile,
          'propertyName': 'margin-left',
          'gutters': 0,
          'gaps': 1,
          'columns': 0,
        )
      );

      @include from('md') {
        margin-left: 0;
      }
    }

    .sliderItem {
      @include setPropertyValue(
        (
          'values': $mobile,
          'propertyName': 'width',
          'gutters': 0,
          'gaps': 0,
          'columns': 3,
        )
      );

      @include setPropertyValue(
        (
          'values': $mobile,
          'propertyName': 'margin-left',
          'gutters': 0,
          'gaps': 1,
          'columns': 0,
        )
      );

      @include from('l') {
        @include setPropertyValue(
          (
            'values': $desktop,
            'propertyName': 'width',
            'gutters': 0,
            'gaps': 0,
            'columns': 2,
          )
        );

        @include setPropertyValue(
          (
            'values': $desktop,
            'propertyName': 'margin-left',
            'gutters': 0,
            'gaps': 1,
            'columns': 0,
          )
        );
      }
    }
  }

  &.xs {
    .slider {
      @include from('md') {
        margin-left: 0;
      }
    }

    .sliderItem {
      width: 165px;

      @include from('lg') {
        width: 180px;
      }

      margin-left: theme($spacings, 16);
    }
  }

  &.m {
    .slider {
      @include setPropertyValue(
        (
          'values': $mobile,
          'propertyName': 'margin-left',
          'gutters': 0,
          'gaps': 1,
          'columns': 0,
        )
      );

      @include from('md') {
        margin-left: 0;
      }
    }

    .sliderItem {
      @include setPropertyValue(
        (
          'values': $mobile,
          'propertyName': 'width',
          'gutters': 0,
          'gaps': 0,
          'columns': 5.5,
        )
      );

      @include setPropertyValue(
        (
          'values': $mobile,
          'propertyName': 'margin-left',
          'gutters': 0,
          'gaps': 1,
          'columns': 0,
        )
      );

      @include from('md') {
        @include setPropertyValue(
          (
            'values': $desktop,
            'propertyName': 'width',
            'gutters': 0,
            'gaps': 0,
            'columns': 3,
          )
        );

        @include setPropertyValue(
          (
            'values': $desktop,
            'propertyName': 'margin-left',
            'gutters': 0,
            'gaps': 1,
            'columns': 0,
          )
        );
      }
    }
  }

  &.xxs {
    .slider {
      @include setPropertyValue(
        (
          'values': $mobile,
          'propertyName': 'margin-left',
          'gutters': 0,
          'gaps': 0,
          'columns': 0,
        )
      );

      @include from('md') {
        margin-left: 0;
      }
    }

    .sliderItem {
      @include setPropertyValue(
        (
          'values': $mobile,
          'propertyName': 'width',
          'gutters': 0,
          'gaps': 0,
          'columns': 3,
        )
      );

      // & + .sliderItem {
      @include setPropertyValue(
        (
          'values': $mobile,
          'propertyName': 'margin-left',
          'gutters': 0,
          'gaps': 1,
          'columns': 0,
        )
      );
      // }

      @include from('md') {
        @include setPropertyValue(
          (
            'values': $desktop,
            'propertyName': 'width',
            'gutters': 0,
            'gaps': 0,
            'columns': 2,
          )
        );

        @include setPropertyValue(
          (
            'values': $desktop,
            'propertyName': 'margin-left',
            'gutters': 0,
            'gaps': 1,
            'columns': 0,
          )
        );
      }
    }
  }
}
