@import 'styles/theme';

.CardProduct {
  position: relative;
  border-radius: theme($borderRadius, 'default');
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border-radius: 0;

  .cardContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .cardMediaWrapper {
    cursor: pointer;
  }

  .cardLink {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
  }

  .compareAtPrice {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    color: theme($colors, 'error-compare-at-price');
  }

  .cardTop {
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 2;
    flex-direction: row;
    top: 0;
    padding: theme($spacings, 10);

    @include setPropertyValue(
      (
        'values': $mobile,
        'propertyName': 'padding',
        'gutters': 0,
        'gaps': 1,
        'columns': 0,
      )
    );

    .cardRight {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-left: 0;
      flex: 1;
      justify-content: flex-end;

      @include from('md') {
        flex-flow: row wrap;
        justify-content: flex-start;
      }
    }
  }

  .label {
    margin-bottom: 4px;
    margin-right: 4px;
  }

  .cardImages {
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    border-radius: theme($borderRadius, 'default');
  }

  .addToCart {
    padding: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-sizing: border-box;
    top: 0px;
  }

  .hoverImage {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .cardBottom {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    height: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 0;
    padding: 0 8px;
    margin-top: 12px;

    @include from('md') {
      margin-top: 16px;
    }

    .cardRight {
      padding-top: 4px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-left: 0;
      flex-flow: row wrap;
    }

    .cardLeft {
      text-align: center;
    }
  }

  .cardBottom {
    margin-top: theme($spacings, 20);
    @include from('md') {
      margin-top: theme($spacings, 20);
      border-radius: theme($borderRadius, 'default');

      @include set-transition(
        (
          'background': 'default',
        )
      );
    }
  }

  .cta {
    margin-top: theme($spacings, 8);
    + .cardBottom {
      margin-top: theme($spacings, 6);
    }
  }

  &.isFullWidth {
    border-radius: 0;
  }
}
