@import 'styles/theme';

.SliderArrow {
  width: 50px;
  height: 50px;
  padding: 0;
  cursor: pointer;

  .arrowIcon {
    display: block;
    &.black {
      path {
        stroke: theme($colors, 'white');
      }
    }
    path {
      stroke: theme($colors, 'black');
    }
  }

  &.black {
    .arrowIcon path {
      stroke: theme($colors, 'white');
    }
  }
}
