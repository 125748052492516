@import 'styles/theme';

.TagPreOrder {
  display: inline-flex;
  align-items: center;

  .label {
    display: flex;
    align-items: center;
  }
}
