@import 'styles/theme';

button.sliderArrow {
  position: absolute;
  top: 50%;

  &.hide {
    display: none;
  }

  &.layout-s {
    width: 30px;
    height: 30px;

    svg {
      width: 30px;
    }
  }

  &.layout-xs {
    width: 30px;
    height: 30px;

    svg {
      width: 24px;
    }
  }

  @include from('md') {
    display: flex;
  }
}

.leftArrow {
  left: 0;
  transform: translate(-20%, -50%) rotate(180deg);

  &.inside {
    transform: translate(20%, -50%) rotate(180deg);
  }
}

.rightArrow {
  right: 0;
  transform: translate(20%, -50%) rotate(0deg);

  &.inside {
    transform: translate(-20%, -50%) rotate(0deg);
  }
}
